:root {
  --white: #fff;
}

* {
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;

  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
}

.banner {
  width: 95%;
  background-color: rgb(18, 158, 175) !important;
  margin: auto;
  margin-top: 1.5rem;
}

.banner-title {
  font-weight: 800;
  font-size: 2.5rem !important;
  font-family: 'Brandon Grotesque Regular';
  color: white;
  margin-bottom: 0;
}

.cardBody {
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.floatRight {
  color: #001E60;
  font-family: 'Pacifico', cursive;
  font-size: 1.8rem;
  position: absolute;
  bottom: 0.25rem;
  right: 1rem;
}

.floatLeft {
  color: #001E60;
  font-family: 'Pacifico', cursive;
  font-size: 1.8rem;
  position: absolute;
  bottom: 0.25rem;
  left: 1rem;
}

.logo {
  height: 55px;
  width: auto;
}

.tsgincLogo {
  height: 110px;
  width: auto;
}

.instructionsContainer {
  margin: 1rem auto;
}

.noBottomMargin {
  margin-bottom: 0;
}

.stepText {
  font-size: 1.25rem;
  font-weight: bold;
}

.anchorBottom {
  position: absolute;
  bottom: 0px;
  margin-bottom: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div.modal-body {
  font-family: 'Calibri';
  font-size: 14.5px;
  margin: 0;
}

div.modal-body p {
  margin: 0;
}

#verticalMargin {
  margin-top: 0 !important;
  margin-bottom: 1rem;
}

.modal-body br {
  display: block;
  margin-bottom: 0px;
  font-size: 0px;
  line-height: 0px;
}

.disclaimer {
  color: #a5a5a6;
  font-size: 10.5px;
}

.underline {
  text-decoration-line: underline;
}

.card {
  background-color: #ebebeb;
}

.card-title {
  font-size: 1.5rem;
  text-align: center;
}

.card-text {
  text-align: center;
}

.required {
  color: red;
}

.no-margin {
  margin: 0;
}

label {
  display: block;
}

.pdfViewer {
  width: 100%;
  min-height: 100vh;
}

.btn-container {
  text-align: end;
}

.btn-container-mobile {
  text-align: end;
}

.btn-margin {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.btn-margin-mobile {
  width: 50%;
  margin: auto;
  margin-bottom: 1rem;
}

footer {
  height: auto;
  width: 100vw;
  margin: 5rem 0;
  text-align: center;
}

footer p {
  color: #ACB1B4;
  font-size: 13px;
}

.justify-center {
  justify-content: center;
}

/*#region TOOLTIP CSS */
.tooltipText {
  font-size: 0.8rem;
  color:#0d6efd;
  cursor: pointer;
}
.tooltipText:hover {
  background-color: #ccc;
}

.circleBackground {
  font-size: 1.4rem;
  border-radius: 1.2rem;
  padding: 4px;
  background-color: #0d6efd;
  color: white;
}

.tooltip-inner {
  height: 300px;
  max-width: 50vw;
  color: black;
  text-align: left;
  overflow-y: scroll;
  border: 1px solid black;
  background-color: #ebebeb;
  padding: 1rem;
}

.tooltipLogo {
  height: 40%;
}

.tooltip {
  --bs-tooltip-opacity: 1 !important;
}
/*#endregion */

@media print {
  .pagebreak { page-break-after: always; } /* page-break-after works, as well */
}

@media (max-width: 576px) {
  .btn-container {
    text-align: center;
  }

  /* for tooltip image*/
  .col-sm-1 {
    text-align: center;
    height: 75%;
  }
}

@media (max-width: 700px) {
  .tooltipLogo {
    height: 25%;
  }
}

@media (max-width: 800px) and (min-width: 701px) {
  .tsgincLogo {
    height: 95px;
  }
}

@media (max-width: 700px) {
  .tsgincLogo {
    height: 70px;
  }
}